import React, {useState , useEffect} from 'react';
import { FaInstagram, FaLinkedin } from "react-icons/fa";


const Footer = ({showBelow}) => {
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        }else {
            if (show) setShow(false)
        }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: `smooth`})
    }

    return (
        <footer className="rn-footer-area bg-color-extra07">
            <div className="wrapper">
                <div className="copyright ptb--60">
                    <div className="inner">
                        <div className="container">
                            <div className="row">
                                <div className="footer-text-box">
                                    <p className="copyright-text text-center">© SAPÊ ARQUITETOS todos os direitos reservados {new Date().getFullYear()} site por<a target="_blank" href="https://github.com/Gcarechio">Gabriel Carechio</a>.</p>
                                </div>
                                <div className="social-icon-box">
                                <a target="_blank" href="https://www.linkedin.com/company/sap%C3%AA-arquitetos/"><FaLinkedin className='social-icon'/></a>
                                    <a target="_blank" href="https://www.instagram.com/sapearquitetos/?hl=en"><FaInstagram className='social-icon'/></a>
                                </div>
                            </div>
                        </div>
                        <div className="back-to-top">
                            <div className="backtop" onClick={handleClick}>
                                <span className="top"></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
         
        </footer>
    )
}
export default Footer
